<div class="modal fade bd-example-modal-lg" id="id-modal-multimedia" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true"
     [id]="idModal" [attr.aria-labelledby]="labelModal" (keydown.escape)="closeModal()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="position-container">
                    <button id="id-close-modal" type="button" class="modal-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
                        <app-close></app-close>
                    </button>
                    <div>
                        <img *ngIf="mediaType=='image'" class="modal-image" [src]="mediaSource" alt="Modal Image">
                        <div class="video-container">
                            <video #videoModal *ngIf="mediaType=='video'" [src]="mediaSource" playsinline controls></video>
                        </div>
                        <div *ngIf="mediaType=='document'" class="document-shower">
                            <embed [src]="mediaSource | safe" type="application/pdf"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
