<div class="box-header">
    <div class="header-wrapper">
        <button id="id-new-chat" type="button" class="new-chat-button" [disabled]="!isInteractionAllowed || talkStarted" (click)="newChat()">
            <img [src]="ajmanImage" alt="Ajman icon">
        </button>
        <div class="inline-header">
            <app-account></app-account>
            <app-languages></app-languages>
        </div>
    </div>
</div>
