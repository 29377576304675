import { Injectable } from '@angular/core';
import { Application, Assets, Sprite } from 'pixi.js';

import { ExaIdleAnimation } from '../../animations/idle';
import { ExaLoadingAnimation } from '../../animations/loading';
import { ExaSpeakingAnimation } from '../../animations/speaking';
import { ExaListeningAnimation } from "../../animations/listening";

@Injectable({
    providedIn: 'root',
})
export class AnimationService {
    private static instance: AnimationService;

    private app!: Application;
    private assistant: Sprite | null = null;
    private animations: any[] = [];
    private container: any;
    private loaded: boolean = false;

    private constructor() {
        if (AnimationService.instance) {
            return AnimationService.instance;
        }

        this.app = new Application();
        AnimationService.instance = this;
    }

    // getters
    static getInstance(): AnimationService {
        if (!AnimationService.instance) {
            AnimationService.instance = new AnimationService();
        }
        return AnimationService.instance;
    }

    async init(animationContainer: any) {
        if (this.loaded) {
            return; // Prevent reinitialization if already loaded
        }

        this.container = animationContainer;

        await this.app.init({
            backgroundAlpha: 0,
            resizeTo: animationContainer.nativeElement,
            resolution: window.devicePixelRatio || 1,
            antialias: true,
            autoDensity: true,
            powerPreference: 'high-performance',
        });

        animationContainer.nativeElement.appendChild(this.app.canvas);

        this.loaded = true;
        await this.scenarioPreparation();
    }

    // Destroy the current instance of the application
    destroyAnimationApp() {
        //this.app.destroy(true);
        this.app = new Application();
        this.loaded = false;
    }

    async renderExaAssistant(url: string) {
        if (!this.loaded) {
            throw new Error('Application not initialized.');
        }

        this.assistant = new Sprite(await Assets.load(url));
        this.assistant.anchor.set(0.5);
        this.assistant.x = this.app.renderer.width / 2;
        this.assistant.y = this.app.renderer.height / 2;

        this.app.stage.addChild(this.assistant);
    }

    async scenarioPreparation() {
        await this.renderExaAssistant('/assets/images/ajmanExaAssistModel.svg');

        this.animations = [
            {
                name: 'idle',
                animation: new ExaIdleAnimation(this.app),
                active: false,
            },
            {
                name: 'listening',
                animation: new ExaListeningAnimation(this.app),
                active: false,
            },
            {
                name: 'loading',
                animation: new ExaLoadingAnimation(this.app),
                active: false,
            },
            {
                name: 'speaking',
                animation: new ExaSpeakingAnimation(this.app),
                active: false,
            },
        ];

        // Optionally start an initial animation
        // this.startAnimation('idle');
        this.onResize();
    }

    startAnimation(animationName: string) {
        if (!this.loaded) return;

        this.animations.forEach((animation) => {
            if (animation.active) {
                animation.active = false;
                animation.animation.destroy();
            }
        });

        this.animations = this.animations.map((animation) => {
            if (animation.name === animationName) {
                animation.active = true;
                animation.animation.init();

                this.onResizeContainer()
            }

            return animation;
        });
    }

    startDefaultAnimation() {
        this.startAnimation('idle');
    }

    onResize() {
        const [containerWidth, containerHeight] = [250, 250]; // TODO: struktura character komponente pothitno da se sredi
        // const { width: containerWidth, height: containerHeight } = this.container.nativeElement.getBoundingClientRect();
        console.log(this.container.nativeElement);
        console.log(this.container.nativeElement.style);
        this.app.renderer.resize(containerWidth, containerHeight);

        if (this.assistant) {
            const originalWidth = this.assistant.texture.width;
            const originalHeight = this.assistant.texture.height;

            const scaleX = containerWidth / originalWidth;
            const scaleY = containerHeight / originalHeight;
            const scaleFactor = Math.min(scaleX, scaleY);

            this.assistant.scale.set(scaleFactor);

            this.assistant.x = containerWidth / 2;
            this.assistant.y = containerHeight / 2;
        }

        this.onResizeContainer();
    }

    onResizeContainer() {
        const activeAnimation = this.animations.find(
            (animation) => animation.active
        );

        if (activeAnimation && this.assistant) {
            activeAnimation.animation.container.scale.set(
                this.assistant.scale.x,
                this.assistant.scale.y
            );
        }
    }
}
